exports.components = {
  "component---src-pages-360-test-js": () => import("./../../../src/pages/360test.js" /* webpackChunkName: "component---src-pages-360-test-js" */),
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-article-360-js": () => import("./../../../src/pages/article-360.js" /* webpackChunkName: "component---src-pages-article-360-js" */),
  "component---src-pages-article-check-js": () => import("./../../../src/pages/article-check.js" /* webpackChunkName: "component---src-pages-article-check-js" */),
  "component---src-pages-de-js": () => import("./../../../src/pages/de.js" /* webpackChunkName: "component---src-pages-de-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-netpoint-check-js": () => import("./../../../src/pages/netpoint-check.js" /* webpackChunkName: "component---src-pages-netpoint-check-js" */),
  "component---src-pages-randomplayer-js": () => import("./../../../src/pages/randomplayer.js" /* webpackChunkName: "component---src-pages-randomplayer-js" */),
  "component---src-pages-randomplayerde-js": () => import("./../../../src/pages/randomplayerde.js" /* webpackChunkName: "component---src-pages-randomplayerde-js" */),
  "component---src-pages-randomplayerdes-js": () => import("./../../../src/pages/randomplayerdes.js" /* webpackChunkName: "component---src-pages-randomplayerdes-js" */),
  "component---src-pages-randomplayern-js": () => import("./../../../src/pages/randomplayern.js" /* webpackChunkName: "component---src-pages-randomplayern-js" */),
  "component---src-pages-randomplayersat-1-js": () => import("./../../../src/pages/randomplayersat1.js" /* webpackChunkName: "component---src-pages-randomplayersat-1-js" */),
  "component---src-pages-testsat-1-js": () => import("./../../../src/pages/testsat1.js" /* webpackChunkName: "component---src-pages-testsat-1-js" */),
  "component---src-pages-thankyou-js": () => import("./../../../src/pages/thankyou.js" /* webpackChunkName: "component---src-pages-thankyou-js" */),
  "component---src-pages-videoplayer-new-js": () => import("./../../../src/pages/videoplayer-new.js" /* webpackChunkName: "component---src-pages-videoplayer-new-js" */),
  "component---src-pages-videoplayerv-2-js": () => import("./../../../src/pages/videoplayerv2.js" /* webpackChunkName: "component---src-pages-videoplayerv-2-js" */),
  "component---src-templates-blog-post-js": () => import("./../../../src/templates/blog-post.js" /* webpackChunkName: "component---src-templates-blog-post-js" */),
  "component---src-templates-category-js": () => import("./../../../src/templates/category.js" /* webpackChunkName: "component---src-templates-category-js" */),
  "component---src-templates-page-js": () => import("./../../../src/templates/page.js" /* webpackChunkName: "component---src-templates-page-js" */)
}

